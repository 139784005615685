<template>
   <div class="max-w-6xl pb-8 mx-auto px-6">
        <div class="max-w-7xl mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:px-8" v-if="form.regPlate">
          <div class="text-center">
            <p class="mt-1 text-4xl font-semibold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-4xl">Get a quote for your vehicle <span class="md:ml-2 rounded-md bg-yellow-400 text-4xl md:text-4xl h-20 uppercase font-bold leading-normal w-full px-3 text-center bg-gray-100 focus:border-gray-100 border-gray-100 relative">{{ form.regPlate }}</span></p>
          </div>
        </div>
      <form-wizard title="" shape="tab" error-color="#f04444" @on-error="handleErrorMessage" @on-validate="handleValidation" @on-complete="onComplete" subtitle="" color="#2e2954" finishButtonText="Get my valuation" > 
      <div v-if="errorMessage" class="mb-6">
         <p class="text-sm">
            <span class="w-full justify-center inline-flex items-center px-3 py-2 rounded-lg border-2 border-red-500 text-red-500 text-sm bg-white" >
               <span class="sr-only" >We will not contact you for any other than the sale of your vehicle and will not share your data with any third parties.</span > 
               <svg class="h-6 w-6 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
               </svg>
               {{ errorMessage }} 
            </span>
         </p>
      </div>
      <tab-content :before-change="validateStepOne" icon="ti ti-car" title="Car details" >
         <div class="shadow-md sm:shadow-md">
            <div class="bg-white pt-6 pb-10 px-4 space-y-6 sm:pb-12 sm:pt-10 sm:px-8 sm:rounded-lg" >
               <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-3"> <label for="mileage" class="block text-sm font-medium text-gray-700" >Mileage</label > <input min="1" v-model.number="form.mileage" placeholder="Enter mileage" type="number" name="mileage" id="mileage" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" /> </div>
                  <div class="col-span-6 sm:col-span-3">
                     <label for="service-history" class="block text-sm font-medium text-gray-700" >Service history</label > 
                     <select v-model="form.serviceHistory" id="service-history" name="service-history" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" >
                        <option value="Full">Full</option>
                        <option value="Partial">Partial</option>
                        <option value="First Not Due">First Not Due</option>
                        <option value="None">None</option>
                     </select>
                  </div>
                  <div class="col-span-6 sm:col-span-3"> <label for="service-history" class="block text-sm font-medium text-gray-700" >Last service date</label > <input v-model="form.lastServiceDate" type="date" id="service-history" name="service-history" placeholder="Click to select" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" /> </div>
                  <div class="col-span-6 sm:col-span-3"> <label for="service-history" class="block text-sm font-medium text-gray-700" >MOT expiry</label > <input v-model="form.motExpiryDate" type="date" id="service-history" name="service-history" placeholder="Click to select" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" /> </div>
                  <!-- Air conditioning faults --> 
                  <div class="col-span-6 sm:col-span-3">
                     <label for="aircon-fully-functioning" class="block text-sm font-medium text-gray-700" >Air Conditioning fully functioning?</label > 
                     <select v-model="form.airconFullyFunctioning" id="aircon-fully-functioning" name="aircon-fully-functioning" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" >
                        <option :value="false">No</option>
                        <option :value="true">Yes</option>
                     </select>
                  </div>
                  <!-- Two or more keys --> 
                  <div class="col-span-6 sm:col-span-3">
                     <label for="two-or-more-keys" class="block text-sm font-medium text-gray-700" >Two or more keys?</label > 
                     <select v-model="form.twoOrMoreKeys" id="two-or-more-keys" name="two-or-more-keys" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" >
                        <option :value="false">No</option>
                        <option :value="true">Yes</option>
                     </select>
                  </div>
               </div>
               <div class="col-span-12">
                  <label for="above-standard-spec" class="block text-sm font-medium text-gray-700" >Does your car have any specification above standard spec?</label > 
                  <select v-model="form.aboveStandardSpec" id="above-standard-spec" name="above-standard-spec" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" >
                     <option :value="false">No</option>
                     <option :value="true">Yes</option>
                  </select>
                  <div v-if="form.aboveStandardSpec" class="mt-4 border-l-2 pl-4 border-gray-200" >
                     <label for="above-standard-spec-details" class="block text-sm font-medium text-gray-700" > Please describe any faults </label> 
                     <div class="mt-2"> <textarea id="above-standard-spec-details" v-model="form.aboveStandardSpecDetails" name="above-standard-spec-details" rows="6" class="p-2 text-sm shadow-full border focus:ring-gray-700 focus:border-gray-700 block w-full sm:text-sm border-gray-300 rounded-md" ></textarea> </div>
                  </div>
               </div>
            </div>
         </div>
      </tab-content>
      <tab-content icon="ti ti-heart-broken" title="Car Faults / Damage">
         <div class="shadow-md sm:shadow-md">
            <div class="bg-white pt-6 pb-10 px-4 space-y-6 sm:pb-12 sm:pt-10 sm:px-8 sm:rounded-lg" >
               <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-3">
                     <label for="wheel-damage" class="block text-sm font-medium text-gray-700" >Any damage to wheels?</label > 
                     <select v-model="form.damageToWheels" id="wheel-damage" name="wheel-damage" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" >
                        <option :value="false">No</option>
                        <option :value="true">Yes</option>
                     </select>
                     <div v-if="form.damageToWheels" class="mt-4 border-l-2 pl-4 border-gray-200" > <label for="wheels-damaged" class="block text-sm font-medium text-gray-700" >How many are damaged?</label > <input type="number" min="1" max="4" v-model="form.damageToWheelsCount" name="wheels-damaged" id="wheels-damaged" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" /> </div>
                  </div>
                  <!-- Tyre damage --> 
                  <div class="col-span-6 sm:col-span-3">
                     <label for="wheels-requiring-replacement" class="block text-sm font-medium text-gray-700" >Do any tyres need replaced?</label > 
                     <select v-model="form.wheelsRequireReplacement" id="wheels-requiring-replacement" name="wheels-requiring-replacement" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" >
                        <option :value="false">No</option>
                        <option :value="true">Yes</option>
                     </select>
                     <div v-if="form.wheelsRequireReplacement" class="mt-4 border-l-2 pl-4 border-gray-200" > <label for="wheels-require-replacement-count" class="block text-sm font-medium text-gray-700" >How many require replacement?</label > <input type="number" min="1" max="4" v-model="form.wheelsReplacementCount" name="wheels-require-replacement-count" id="wheels-require-replacement-count" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" /> </div>
                  </div>
                  <!-- Damage to Bodywork --> 
                  <div class="col-span-6 sm:col-span-3">
                     <label for="damage-to-bodywork" class="block text-sm font-medium text-gray-700" >Any damage to bodywork?</label > 
                     <select v-model="form.damageToBodyWork" id="damage-to-bodywork" name="damage-to-bodywork" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" >
                        <option :value="false">No</option>
                        <option :value="true">Yes</option>
                     </select>
                     <div v-if="form.damageToBodyWork" class="mt-4 border-l-2 pl-4 border-gray-200" >
                        <label for="damage-to-bodywork-details" class="block text-sm font-medium text-gray-700" > Please describe any damage </label> 
                        <div class="mt-2"> <textarea id="damage-to-bodywork-details" v-model="form.damageToBodyWorkDetails" name="damage-to-bodywork-details" rows="4" class="p-2 text-sm shadow-full border focus:ring-gray-700 focus:border-gray-700 block w-full sm:text-sm border-gray-300 rounded-md" ></textarea> </div>
                     </div>
                  </div>
                  <!-- Any mechanical faults --> 
                  <div class="col-span-6 sm:col-span-3">
                     <label for="any-mechanical-faults" class="block text-sm font-medium text-gray-700" >Any mechanical faults?</label > 
                     <select v-model="form.mechanicalFaults" id="any-mechanical-faults" name="any-mechanical-faults" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" >
                        <option :value="false">No</option>
                        <option :value="true">Yes</option>
                     </select>
                     <div v-if="form.mechanicalFaults" class="mt-4 border-l-2 pl-4 border-gray-200" >
                        <label for="any-mechanical-faults-details" class="block text-sm font-medium text-gray-700" > Please describe any faults </label> 
                        <div class="mt-2"> <textarea id="any-mechanical-faults-details" v-model="form.mechanicalFaultsDetails" name="any-mechanical-faults-details" rows="4" class="p-2 text-sm shadow-full border focus:ring-gray-700 focus:border-gray-700 block w-full sm:text-sm border-gray-300 rounded-md" ></textarea> </div>
                     </div>
                  </div>
                  <!-- Air conditioning faults --> 
                  <div class="col-span-6 sm:col-span-3">
                     <label for="aircon-fully-functioning" class="block text-sm font-medium text-gray-700" >Air Conditioning fully functioning?</label > 
                     <select v-model="form.airconFullyFunctioning" id="aircon-fully-functioning" name="aircon-fully-functioning" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" >
                        <option :value="false">No</option>
                        <option :value="true">Yes</option>
                     </select>
                  </div>
               </div>
            </div>
         </div>
      </tab-content>
      <tab-content title="Your details" icon="ti ti-location-pin" :before-change="validateStepThree" >
         <div class="shadow sm:rounded-md">
            <div class="bg-white pt-6 pb-10 px-4 space-y-6 sm:pb-12 sm:pt-10 sm:px-8 sm:rounded-lg" >
               <!-- Name --> 
               <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-3"> <label for="name" class="block text-sm font-medium text-gray-700" >Name</label > <input v-model="form.name" placeholder="Enter your name" type="text" name="name" id="name" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" /> </div>
                  <!-- Email --> 
                  <div class="col-span-6 sm:col-span-3"> <label for="email" class="block text-sm font-medium text-gray-700" >Email Address</label > <input v-model="form.email" placeholder="Enter your email" type="email" name="email" id="email" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" /> </div>
                  <!-- Postcode --> 
                  <div class="col-span-6 sm:col-span-3"> <label for="postcode" class="block text-sm font-medium text-gray-700" >Postcode</label > <input v-model="form.postcode" placeholder="Enter your post code" type="text" name="postcode" id="postcode" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" /> </div>
                  <!-- Telephone --> 
                  <div class="col-span-6 sm:col-span-3"> <label for="telephone" class="block text-sm font-medium text-gray-700" >Telephone</label > <input v-model="form.telephone" placeholder="Enter your telephone" type="tel" name="telephone" id="telephone" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" /> </div>
               </div>
               <div class="mt-4 col-span-12">
                  <p class="text-sm">
                     <span class="inline-flex justify-center w-full items-center px-3 py-2 rounded-lg text-sm" >
                        <span class="sr-only" >We will not contact you for any other than the sale of your vehicle and will not share your data with any third parties.</span > 
                        <svg class="h-6 w-6 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                           <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                        </svg>
                        We will not contact you for anything other than the sale of your vehicle and will not share your data with any third parties. 
                     </span>
                  </p>
               </div>
            </div>
         </div>
      </tab-content>
      </form-wizard> 
   </div>
</template>
<script>
   import axios from "axios";
   
   export default {
     name: "Quote",
     mounted() {
       if (this.$route.query.reg) {
         this.form.regPlate = this.$route.query.reg
       } else {
        this.$router.push({ path: '/' })
       }
     },
     data() {
       return {
         errorMessage: null,
         form: {
           regPlate: '',
           mileage: 0,
           serviceHistory: "Full",
           lastServiceDate: new Date().toISOString().slice(0, 10),
           motExpiryDate: new Date().toISOString().slice(0, 10),
           damageToWheels: false,
           damageToWheelsCount: 0,
           wheelsRequireReplacement: false,
           wheelsReplacementCount: 0,
           damageToBodyWork: false,
           damageToBodyWorkDetails: "",
           twoOrMoreKeys: false,
           mechanicalFaults: false,
           mechanicalFaultsDetails: "",
           airconFullyFunctioning: false,
           aboveStandardSpec: false,
           aboveStandardSpecDetails: "",
           name: "",
           email: "",
           postcode: "",
           telephone: ""
         }
       };
     },
     methods: {
       encode(data) {
         return Object.keys(data)
           .map(
             key => `${encodeURIComponent(key)}=${String(encodeURIComponent(data[key]))}`
           )
           .join("&");
       },
       handleValidation: function(isValid, tabIndex){
         console.log('Tab: '+tabIndex+ ' valid: '+isValid)
       },
       validateStepOne() {
         return new Promise((resolve, reject) => {
   
           if (this.form.mileage == 0) {
             reject("Please enter vehicle mileage");
           }
   
           resolve(true);
         });
       },
       validateStepThree() {
         return new Promise((resolve, reject) => {
           if (this.form.name == "") {
             reject("Please enter your name");
           }
   
           const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   
           if (this.form.email == "") {
             reject("Please enter your email address");
           }
   
           if (!emailRegex.test(this.form.email)) {
             reject("Please enter a valid email address");
           }
   
           if (this.form.postcode == "") {
             reject("Please enter your postcode");
           }
   
           if (
             !/^[a-zA-Z]{1,2}[0-9]{1,2} ?[0-9][a-zA-Z]{2}$/i.test(
               this.form.postcode
             )
           ) {
             reject("Please enter a valid post code");
           }
   
           const telephoneRegex = /^(?:0|\+?44)(?:\d\s?){9,10}$/i;
   
           if (this.form.telephone == "") {
             reject("Please enter your telephone number");
           }
   
           if (!telephoneRegex.test(this.form.telephone)) {
             reject("Please enter a valid telephone number");
           }
   
           resolve(true);
         });
       },
       handleErrorMessage: function(errorMsg) {
         this.errorMessage = errorMsg;
       },
       onComplete() {
         const axiosConfig = {
           header: { "Content-Type": "application/x-www-form-urlencoded" }
         };
         axios.post(
           "/",
           this.encode({
             "form-name": "Quote Request",
             ...this.form
           }),
           axiosConfig
         );
       }
     }
   };
</script>
<style></style>